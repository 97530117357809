#main-content {
  font-size: var(--custom-font-size, 16px) !important;
  letter-spacing: var(--custom-letter-spacing, 0px) !important;
}

.speech-active {
  background-color: yellow;
}

/* font-size */

:root {
  --custom-font-size-scale: 1;
}

:root.large-font {
  --custom-font-size-scale: var(--custom-font-size-scale, 1);
}

.MuiTypography-documentTitle {
  font-size: calc(35pt * var(--custom-font-size-scale)) !important;
}

.MuiTypography-sectionTitle {
  font-size: calc(20pt * var(--custom-font-size-scale)) !important;
}

.MuiTypography-subtitle2 {
  font-size: calc(0.875rem * var(--custom-font-size-scale)) !important;
}

.MuiTypography-caption {
  font-size: calc(16px * var(--custom-font-size-scale)) !important;
}

.MuiInputBase-root {
  font-size: calc(1rem * var(--custom-font-size-scale)) !important;
}

#main-content .MuiButtonBase-root {
  font-size: calc(0.875rem * var(--custom-font-size-scale)) !important;
}

.MuiTypography-h1 {
  font-size: calc(2rem * var(--custom-font-size-scale)) !important;
}

.MuiTypography-h2 {
  font-size: calc(1.5rem * var(--custom-font-size-scale)) !important;
}

.MuiTypography-h3 {
  font-size: calc(3rem * var(--custom-font-size-scale)) !important;
}

.MuiTypography-h4 {
  font-size: calc(2.125rem * var(--custom-font-size-scale)) !important;
}

.MuiTypography-h5 {
  font-size: calc(1.5rem * var(--custom-font-size-scale)) !important;
}

#main-content .MuiTypography-h6 {
  font-size: calc(1.25rem * var(--custom-font-size-scale)) !important;
}

/* Exclude nav with MuiTypography-body1 class */
.MuiTypography-body1:not(nav.MuiTypography-root.MuiTypography-body1) {
  font-size: calc(1rem * var(--custom-font-size-scale)) !important;
}

.MuiTypography-body2 {
  font-size: calc(0.875rem * var(--custom-font-size-scale)) !important;
}

/* high contrast */

:root.high-contrast {
  --custom-background-color: black;
  --custom-text-color: yellow;
  --custom-link-color: cyan;
  background-color: var(--custom-background-color);
}

:root.high-contrast #main-content {
  background-color: var(--custom-background-color, black);
  color: var(--custom-text-color, yellow) !important;
}

:root.high-contrast #main-content .MuiTypography-root {
  color: var(--custom-text-color, yellow) !important;
}

:root.high-contrast #main-content .MuiButton-root {
  color: var(--custom-text-color, yellow) !important;
}

:root.high-contrast #main-content .MuiTab-textColorInherit {
  color: var(--custom-text-color, yellow) !important;
}

:root.high-contrast #main-content .app-logo-search {
  filter: invert(1);
}

:root.high-contrast #main-content .search-banner {
  background: #000453 !important;
}

:root.high-contrast #main-content header.MuiPaper-root {
  background: #000453 !important;
}

:root.high-contrast #main-content .category-btn {
  background: #000453 !important;
}

:root.high-contrast #main-content .MuiPaper-rounded {
  background: #000453 !important;
}

:root.high-contrast #main-content .header-button {
  background: grey !important;
}

:root.high-contrast #main-content .MuiInputBase-adornedEnd {
  background: grey !important;
}

:root.high-contrast #main-content .MuiInputBase-adornedEnd input {
  color: var(--custom-text-color, yellow) !important;
}

:root.high-contrast #main-content .MuiList-root {
  background: grey !important;
}

:root.high-contrast #main-content .MuiList-root ~ .MuiBox-root {
  background: grey !important;
}

:root.high-contrast #main-content .search-container {
  background: grey !important;
}

:root.high-contrast #main-content .handout-preview-card .MuiBox-root {
  background-color: grey !important;
}

:root.high-contrast #main-content .handout-container {
  background-color: grey !important;
}

:root.high-contrast #main-content .handouts-container {
  background-color: black !important;
}

:root.high-contrast #main-content .handouts-container > .MuiBox-root {
  background-color: black !important;
}

:root.high-contrast #main-content .feedback-container {
  background-color: grey !important;
}

:root.high-contrast #main-content .MuiDivider-root {
  background-color: white !important;
}

:root.high-contrast #main-content .breadcrumb-container {
  background: #000453 !important;
}

:root.high-contrast #main-content .handout-actions-container {
  background: #000453 !important;
}

:root.high-contrast #main-content .MuiBreadcrumbs-li > * {
  color: var(--custom-text-color, yellow) !important;
}
