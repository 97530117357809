body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@page {
  /* enforce correct sizing shows on print popup to prevent wrong formatted prints - this prevents changing to A3 or other unsuitable options  */
  size: A4 portrait;
  margin: 0mm;
  margin-top: 8mm;
  margin-bottom: 8mm;
}

/* on the CMS use the printPageBlock between the sections where you want the content to move to next page during prints */
@media print {
  /* hide scrollbars for all  */
  ::-webkit-scrollbar {
    display: none;
  }
  
  .printPageBreak {  
    page-break-inside: avoid;
    page-break-after: always;
  }
}

/* swiper.js */
@media (max-width: 600px) { 
  .swiper {
    width: 85%;
    padding: 4px!important;
  }
}

.swiper-slide  {
  justify-content: center;
  display: flex!important;
}